import { io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import { getGlobalState, setGlobalState } from "./store";
import { isAccountRegistered } from "./blockchain";
import { SERVER_URL, PEER_TIMEOUT_SEC } from "./config";
import {
  flashOff,
  flashOn,
  notify,
  nwBlip,
  playRing,
  composeProposalMessage,
  playPop,
} from "./util";

let socket, fromAccountId;
/**
 * Handshake protocol:
 * - Upon socket initiation, Metamask account id will be sent over in a JOIN message
 * - The server will respond back with a WELCOME message
 * - The server will respond back with a list of logged-in users
 * Transfer protocol:
 * - User will darg/drop a file to the send-panel for a certain online user
 * - User will fill-in metadata for the file
 * - Receiver will be notified over socket about the file, and whether they'd like to accept thie file
 * - Upon receving ACCEPT, the user will upload the file to the backend. This file never gets stored anywhere
 * - Once the file has been uploaded, the receiver will be notified of the url to download the file
 * - Once the file has been downloaded, it can be deleted from the memory
 */

const resetFileTransferStates = () => {
  setGlobalState("acceptFile", false);
  setGlobalState("confirm", { show: false });
  setGlobalState("systemState", "idle");
  setGlobalState("currentAcceptedId", null);
  flashOff();
};
/**
 * send the json request. ethereumId is added to each request
 */
const sendJSON = async (obj) => {
  if (!(await isAccountRegistered())) return;
  const _obj = JSON.stringify({
    ...obj,
    fromAccountId,
  });
  console.log(`Sending: ${_obj}`);
  socket.emit("MESSAGE", _obj);
};

/** Processor for each type of message */

const processWELCOME = () => {
  setGlobalState("notification", {
    type: "success",
    text: "Connected to MediSend Network",
  });
  setGlobalState("loading", false);
};

const processCLIENT_LIST = (body) => {
  body.allClients.push("Non-Medisend"); // Add non-medisend as permanently active user (replace with file)
  setGlobalState(
    "activeClientList",
    body.allClients.filter((client) => client !== fromAccountId)
  );
  nwBlip();
};

const processPROPOSE = (body) => {
  const { patientName, patientDOB, proposedId } = body;
  const remoteFromAccountId = body.fromAccountId;
  const remoteFromAccountObj =
    getGlobalState("userLedger")[remoteFromAccountId];
  const { name } = getGlobalState("userLedger")[body.fromAccountId];
  resetFileTransferStates();
  const doReject = (reason, shouldIdle = false) => {
    if (getGlobalState("acceptFile") == false) {
      sendJSON({
        type: "REJECT",
        reason,
        fromAccountId,
        toAccountId: remoteFromAccountId,
        proposedId,
      });
      setGlobalState("notification", {
        type: "warning",
        text: `Declined receiving file from ${name} : ${reason}`,
      });
      if (!shouldIdle) return;
      setGlobalState("missedCalls", [
        ...getGlobalState("missedCalls"),
        remoteFromAccountId,
      ]);
      resetFileTransferStates();
    }
  };

  const doAccept = () => {
    acceptedId = `0x${uuidv4().replaceAll("-", "")}`;
    setGlobalState("currentAcceptedId", acceptedId);

    sendJSON({
      type: "ACCEPT",
      fromAccountId,
      toAccountId: remoteFromAccountId,
      proposedId,
      acceptedId,
    });

    setGlobalState("notification", {
      type: "success",
      text: `Accepted receiving file from ${name}`,
    });
    setGlobalState("systemState", "waitingForDownload");
    setGlobalState("acceptFile", true);
    setGlobalState("confirm", { show: false });
  };
  if (!remoteFromAccountObj) return doReject("UNKNOWN_USER");
  if (getGlobalState("systemState") !== "idle") {
    setGlobalState("missedCalls", [
      ...getGlobalState("missedCalls"),
      remoteFromAccountId,
    ]);
    return doReject("SYSTEM_BUSY");
  } // if not idle, reject
  setGlobalState("systemState", "deciding"); // change to deciding state
  playRing();
  flashOn(`${name} calling ...`);

  if (document.visibilityState !== "visible") {
    notify(
      `${name} calling`,
      `${name} wants to send ${patientName} - DOB ${patientDOB}`
    );
  }

  if (getGlobalState("acceptFile") === true) {
    setGlobalState("confirm", { show: false });
    setGlobalState("systemState", "idle");
    flashOff();
    return;
  } else {
    setGlobalState("confirm", {
      show: true,
      text: composeProposalMessage({
        ...body,
        sender: remoteFromAccountObj.name,
      }),
      icon: remoteFromAccountObj.icon,
      ok: () => {
        doAccept();
        flashOff();
      },
      cancel: () => {
        doReject("DECLINED", true);
        flashOff();
      },
    });
  }
};

const processACCEPT = (body) => {
  if (executeOnAccept && proposedId != null) {
    if (body.proposedId !== proposedId)
      return setGlobalState("notification", {
        type: "error",
        text: "Invalid ACCEPT id received",
      });
    setGlobalState("notification", {
      type: "success",
      text: `${
        getGlobalState("userLedger")[body.fromAccountId].name
      } Accepted. Transfer will begin shortly`,
    });
    isfileSendDevice = true;
    executeOnAccept(body.acceptedId);
    executeOnAccept = proposedId = null;
  }
  resetFileTransferStates();
};

const processREJECT = (body) => {
  if (getGlobalState("acceptFile") === false) {
    if (isfileSendDevice == true) {
      setGlobalState("notification", {
        type: "error",
        text: `${
          getGlobalState("userLedger")[body.fromAccountId].name
        } Rejected : ${body.reason}`,
      });
      executeOnAccept && executeOnAccept(null);
      executeOnAccept = proposedId = null;
      isfileSendDevice = false;
      if (body.fileName) {
        const fullLedger = getGlobalState("transferLedger");
        console.log("fullLedger~~~~`", fullLedger);
        fullLedger[body.fromAccountId] = fullLedger[body.fromAccountId].filter(
          ({ _name }) => _name !== body.fileName
        );
        setGlobalState("transferLedger", { ...fullLedger });
      }
    }
    resetFileTransferStates();
  }
};

const processACKNOWLEDGE = (body) =>
  setGlobalState("notification", {
    type: "success",
    text: `${
      getGlobalState("userLedger")[body.fromAccountId].name
    } acknowledged receiving file ${body.filename}`,
  });

const processDOWNLOAD = async (body) => {
  console.log("🚀 ~ file: socket.js:182 ~ processDOWNLOAD ~ body:", body);

  // Get the current acceptedId from state
  const currentAcceptedId = getGlobalState("currentAcceptedId");

  // Check if this device accepted the file
  if (currentAcceptedId && body.acceptedId === currentAcceptedId) {
    const res = await fetch(
      `${SERVER_URL}/download?file=${body.proposedId}_${body.acceptedId}_${body.filename}&type=${body?.fileType}`
    );
    setGlobalState("selectedClient", body.fromAccountId);
    setGlobalState("fileDownloadObj", {
      ...body,
      from: getGlobalState("userLedger")[body.fromAccountId],
      url: window.URL.createObjectURL(await res.blob()),
    });
    setGlobalState("systemState", "deciding");
    setGlobalState("currentAcceptedId", null);
  }
};

const processCHAT = async (body) => {
  const ethereumAccount = getGlobalState("ethereumAccount");
  let otherAccountId = body.fromAccountId;
  if (otherAccountId === ethereumAccount) otherAccountId = body.toAccountId;
  const _chatHistory = getGlobalState("chatHistory");
  const __chatHistory = [...(_chatHistory[otherAccountId] || []), body];
  _chatHistory[otherAccountId] = __chatHistory;
  setGlobalState("chatHistory", { ..._chatHistory });
  setGlobalState("selectedClient", otherAccountId);
  setGlobalState("showChat", true);
  if (body.fromAccountId === ethereumAccount) return;
  playPop();
  const name = getGlobalState("userLedger")[otherAccountId].name;
  flashOn(`${name} is chatting ...`);
  if (document.visibilityState !== "visible")
    notify(`${name} says`, body.message);
};

/**
 * Opening the socket to the Medisend server
 */
const openSocket = async () => {
  if (!(await isAccountRegistered())) return;
  fromAccountId = getGlobalState("ethereumAccount");
  socket = io(SERVER_URL);

  socket.on("connect", () => {
    // console.log("Socket.IO connection established");
    setGlobalState("socket", socket);
    sendJSON({ type: "JOIN" });

    const myDeviceId = getGlobalState("myDeviceId");
    socket.emit("register-device", myDeviceId);

    // Listen for the logout notification event
    socket.on("logout-notification", (data) => {
      console.log(`Device ${data.deviceId}  ${data.message}`);
      notify("Medisend", data.message);
      setTimeout(() => setGlobalState("loading", true), 60000);
      //setGlobalState("loading", true);
    });
  });

  socket.on("MESSAGE", (message) => {
    console.log("🚀 ~ file: socket.js:231 ~ socket.on ~ message:", message);
    console.log(`Received: ${message}`);
    const body = JSON.parse(message);
    if (body.type === "WELCOME") return processWELCOME();
    if (body.type === "CLIENT_LIST") return processCLIENT_LIST(body);
    if (body.type === "PROPOSE") return processPROPOSE(body);
    if (body.type === "ACCEPT") return processACCEPT(body);
    if (body.type === "ACKNOWLEDGE") return processACKNOWLEDGE(body);
    if (body.type === "REJECT") return processREJECT(body);
    if (body.type === "DOWNLOAD") return processDOWNLOAD(body);
    if (body.type === "CHAT") return processCHAT(body);
    if (body.type === "FILE_STATUS_UPDATE") {
      setGlobalState("acceptFile", true); // Prevent rejection
      setGlobalState("confirm", { show: false });
      // setGlobalState("notification", {
      //   type: "info",
      //   text: body.message
      // });
      flashOff();
      setGlobalState("systemState", "idle");
      return;
    }

    // Only show warning for truly invalid message types
    // if (!["FILE_STATUS_UPDATE", "FILE_ACCEPTED_ELSEWHERE"].includes(body.type)) {
    //   setGlobalState("notification", {
    //     type: "warning",
    //     text: `Ignoring invalid message type : ${body.type}`,
    //   });
    // }
    // if (body.type === "USER") return processUSER(body);
    setGlobalState("notification", {
      type: "warning",
      text: `Ignoring invalid message type : ${body.type}`,
    });
  });
  socket.on("disconnect", () => {
    console.log("Socket disconnected");
    resetFileTransferStates();
  });
  return socket;
};

let proposedId,
  executeOnAccept,
  acceptedId,
  isfileSendDevice = false;
const sendProposalAndWait = (metadata) =>
  new Promise((resolve, reject) => {
    // send the proposal to the peer with a random value. we'd expect the same value to be echoed back
    proposedId = `0x${uuidv4().replaceAll("-", "")}`;
    sendJSON({
      type: "PROPOSE",
      proposedId,
      ...metadata,
    });
    executeOnAccept = (acceptedId) => {
      setGlobalState("freeze", false);
      if (!acceptedId) return reject({ proposedId });
      return resolve({ proposedId, acceptedId });
    }; // executeOnAccept is executed upon receiving ACCEPT or REJECT by socket.onmessage()
    setGlobalState("freeze", true);
    setTimeout(() => {
      if (!proposedId) return;
      proposedId = executeOnAccept = null;
      setGlobalState("freeze", false);
      setGlobalState("notification", {
        type: "error",
        text: `${
          getGlobalState("userLedger")[metadata.toAccountId].name
        } did not respond in time. Please try again`,
      });
      reject({ proposedId });
    }, PEER_TIMEOUT_SEC * 1000); // wait for a while, and if an ACCEPT has not been recieved, reject()
  });

const rejectAfterPreview = (toAccountId, fileName, reason) => {
  sendJSON({
    type: "REJECT",
    reason,
    fromAccountId: getGlobalState("ethereumAccount"),
    toAccountId,
    fileName,
  });
  setGlobalState("notification", {
    type: "warning",
    text: `Declined receving file from ${
      getGlobalState("userLedger")[fromAccountId].name
    } : ${reason}`,
  });
  // setGlobalState("acceptFile", false);
  // setGlobalState("systemState", "idle");
  resetFileTransferStates();
};

const sendChatMessage = (message) =>
  sendJSON({
    type: "CHAT",
    toAccountId: getGlobalState("selectedClient"),
    fromAccountId: getGlobalState("ethereumAccount"),
    message,
  });

const sendAcknowledgement = (filename) =>
  sendJSON({
    type: "ACKNOWLEDGE",
    toAccountId: getGlobalState("selectedClient"),
    fromAccountId: getGlobalState("ethereumAccount"),
    filename,
  });

export {
  openSocket,
  sendProposalAndWait,
  rejectAfterPreview,
  sendChatMessage,
  sendAcknowledgement,
};

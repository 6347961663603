/**
 * This service is our proxy for Ethereum SmartContracts. This is the only file that should ever access ethereum.
 */

import Web3 from "web3";
import { setGlobalState } from "../store";
import { encrypt, encryptThen } from "../util";
import { SERVER_URL } from "../config";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { osName, browserName } from "react-device-detect";
let web3,
  AccountsContract,
  _userLedger,
  _transferLedger,
  isLoggedInAsAdmin,
  ethAccount;
/**
 * To be called at starting of the App. This method reads all the events since the beginning of time, and sets the state
 * in the Store. All further events are applied to the Store to alter the state of the system incrementally.
 */
const initBlockchain = async () => {
  // we assume that we are using Metamask, and Metamask injects window.ethereum
  await window.ethereum.request({ method: "eth_requestAccounts" });
  web3 = new Web3(window.ethereum);
  if ((await validateMetamask()) === false) return null;
  const accounts = await web3.eth.getAccounts();
  if (!accounts)
    return setGlobalState("notification", {
      text: `Login failed. Are you logged-in to Metamask?`,
      type: "error",
    });

  web3.eth.defaultAccount = ethAccount = accounts[0];
  // subscribe to accout changed events to reset the defaultAccount
  window.ethereum.on(
    "accountsChanged",
    (accounts) => (ethAccount = accounts[0])
  );
  // setup our Accounts Contract instance
  const { abi, networks } = require(`./contracts/Accounts`);
  AccountsContract = new web3.eth.Contract(
    abi,
    networks[await web3.eth.net.getId()].address
  );
  // determine whether the user has logged-in as admin by checking against the owner of the SmartContract
  isLoggedInAsAdmin =
    ethAccount === (await AccountsContract.methods.getOwner().call());
  // ensure that the logged-in user is registered with the SmartContract or is Admin
  if (!isLoggedInAsAdmin && !(await isAccountRegistered())) {
    setGlobalState("loading", false);
    return setGlobalState("notification", {
      text: `This Account is not registered by Medisend. Please contact Admin`,
      type: "error",
    });
  }
  if (isLoggedInAsAdmin) {
    // collect all past events
    await processAccountEvents();
    await processTransferEvents();
    // if all is well, flag it and move ahead
    setGlobalState("ethereumAccount", ethAccount);
    setGlobalState("isLoggedInAsAdmin", isLoggedInAsAdmin);
    setGlobalState("notification", {
      text: `Connected to Ethereum through Metamask`,
      type: "success",
    });
  }

  if (!isLoggedInAsAdmin) {
    const userId = accounts[0];
    // get user wise plan Data
    const getUserwisePlanData = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/getUserWisePlanData?userId=${userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const jsonResponse = await response.json();
        const data = jsonResponse.data;
        return data;
      } catch (err) {
        console.log(err);
      }
    };
    //get user wise device information
    const getUserDeviceData = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/getUserDeviceData?userId=${userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const jsonResponse = await response.json();
        const data = jsonResponse.data;
        setGlobalState("deviceNames", data);
        return data;
      } catch (e) {
        console.log(e);
      }
    };
    //get user wise connected device count
    const getUserWiseDeviceCount = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/getUserWiseDeviceCount?userId=${userId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const jsonResponse = await response.json();
        return jsonResponse.count;
      } catch (e) {
        console.log(e);
      }
    };
    // get plan maxdevice connect count
    const getPlanMaxDeviceCount = async () => {
      try {
        const userwisePlanIdResponse = await getUserwisePlanData();
        const planId = userwisePlanIdResponse.planId;
        const response = await fetch(
          `${SERVER_URL}/getPlanMaxDeviceCount?planId=${planId}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const jsonResponse = await response.json();
        setGlobalState("maxCount", jsonResponse.count);
        return jsonResponse.count;
      } catch (e) {
        console.log(e);
      }
    };

    const UpdateUserPlanStatus = async () => {
      try {
        const response = await fetch(
          `${SERVER_URL}/editstatus?userId=${userId}`,
          {
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        const jsonResponse = await response.json();
        return jsonResponse;
      } catch (e) {
        console.log(e);
      }
    };

    // add device information and check user limit is exeed or not
    const addUserDevice = async (req, res) => {
      try {
        const userWisePlanData = await getUserwisePlanData();
        const expirationTime = userWisePlanData.expirationTime;
        // check use plan is expire or not
        if (
          new Date(expirationTime * 1000).toLocaleString() ===
          new Date(Date.now()).toLocaleString()
        ) {
          await UpdateUserPlanStatus(); //change status is 1 determine plan are expired
          return setGlobalState("notification", {
            text: `This account plan has expired. Please contact Admin to renew the plan`,
            type: "error",
          });
        } else {
          const userDeviceData = await getUserDeviceData(); //get user device data
          const userdeviceId = userDeviceData.map((a) => a.deviceId);
          console.log("user already connect device data", userdeviceId);

          const userDerviceCount = await getUserWiseDeviceCount(); //get user wise device count
          const planMaxDeviceCount = await getPlanMaxDeviceCount(); //get planwise max device count

          const fpPromise = FingerprintJS.load(); //get current device id
          const fp = await fpPromise;
          const result = await fp.get();
          const deviceId = result.visitorId;
          setGlobalState("myDeviceId", deviceId);
          // check my device id are already added in userDevice data
          if (userdeviceId.includes(deviceId)) {
            console.log("same id...");
            // collect all past events
            await processAccountEvents();
            await processTransferEvents();
            // if all is well, flag it and move ahead
            setGlobalState("ethereumAccount", ethAccount);
            setGlobalState("notification", {
              text: `Connected to Ethereum through Metamask`,
              type: "success",
            });
          } else {
            // check user devicecount are graeter then plan max devicecount
            if (userDerviceCount >= planMaxDeviceCount) {
              console.log("user limit exeed....");
              setGlobalState("loading", true);
              setGlobalState("openDialog", true); // open logout dialog
            } else {
              const deviceName = `${browserName}  ${osName}`;
              const response = await fetch(`${SERVER_URL}/userdevice`, {
                method: "post",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  userId: userId,
                  deviceId: deviceId,
                  deviceName: deviceName,
                }),
              });
              const jsonResponse = await response.json();
              console.log("New device ID added", jsonResponse);
              await initBlockchain();
              return jsonResponse;
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    addUserDevice();
  }
};

/**
 * Validate a bunch of things about Metamask, including whether it is installed or not, and whether Ropsten Test Network is selected.
 * It returns false is web3 is missing, and then ensures that network is set to Ropsten (value '3'). There are situations when web3.version.network
 * comes out to be null, in which case, we keep on reading it until we get a non-null value
 */
const validateMetamask = async () =>
  new Promise(async (resolve, reject) => {
    // if web3 is not detected, the user hasnt perhaps installed Metamask
    if (
      window.ethereum === null ||
      !window.ethereum ||
      typeof window.ethereum === "undefined" ||
      !window.ethereum.isMetaMask
    ) {
      setGlobalState("notification", {
        text: "Metamask is not installed or is disabled. Please refresh the page after installing Metamask.",
        type: "error",
      });
      return reject(false);
    }
    resolve(true);
  });

/**
 * Get the balance in finney for the given wallet address.
 * 1 eth = 1000 finney
 * @param {*} address
 */
const getBalance = async (address) => {
  if (address === 0x0 || (await validateMetamask()) === false) return;
  return parseInt(
    web3.utils.fromWei(await web3.eth.getBalance(`${address}`), "finney")
  );
};

/**
 * Obtain a list of all past events and construct the current state of the system.
 * Events are being sent twice for some reason. So, we maintain a hashtable of events,
 * to ensure that we dont process them twice
 */
const eventTransactionHashTable = {};
const chunkSize = 10000000;
const fetchEventsInChunks = async (contract, eventName, fromBlock, toBlock) => {
  let events = [];
  let currentBlock = toBlock;
  // console.log(currentBlock)
  if (toBlock === "latest") {
    currentBlock = await web3.eth.getBlockNumber();
  }
  for (let i = fromBlock; i <= currentBlock; i += chunkSize) {
    const endBlock = Math.min(i + chunkSize - 1, currentBlock);
    const newEvents = await contract.getPastEvents(eventName, {
      fromBlock: i,
      toBlock: endBlock,
    });
    events = events.concat(newEvents);
  }
  return events;
};

/** process UserAdded and UserRemoved events */
const processAccountEvents = async () => {
  // we collect all past AccountAdded and AccountRemoved events, and play them sequentially
  _userLedger = {};
  let ev = { fromBlock: 0, toBlock: "latest" };
  let pastEventsAdded = await fetchEventsInChunks(
    AccountsContract,
    "AccountAdded",
    0,
    "latest"
  );
  const eventList = pastEventsAdded.map(
    ({
      returnValues: {
        _timestamp,
        _address,
        _name,
        _hpio,
        _url,
        _icon,
        _details,
      },
    }) => ({
      timestamp: _timestamp,
      address: _address,
      name: _name,
      hpio: _hpio,
      url: _url,
      icon: _icon,
      details: _details,
    })
  );
  let pastEventsRemoved = await fetchEventsInChunks(
    AccountsContract,
    "AccountRemoved",
    0,
    "latest"
  );
  pastEventsRemoved.forEach(({ returnValues: { _timestamp, _address } }) =>
    eventList.push({ timestamp: _timestamp, address: _address })
  );

  // Add non-medisend user to ledger (replace with file)
  _userLedger["Non-Medisend"] = {
    timestamp: null,
    address: null,
    name: "Non-Medisend",
    hpio: null,
    url: null,
    icon: "https://app.medisend.com.au/logo.png",
    details: "Non-Medisend",
  };

  const sortedEvents = eventList.sort((e1, e2) => e1.timestamp - e2.timestamp);
  sortedEvents.forEach((ev) => {
    if (ev.name) _userLedger[ev.address] = ev;
    else delete _userLedger[ev.address];
  });

  // and finally, set the userLedger
  setGlobalState("userLedger", _userLedger);
  // next, listen to new events
  const validateEvent = (error, event, alias) => {
    if (Boolean(eventTransactionHashTable[event.transactionHash])) return false;
    eventTransactionHashTable[event.transactionHash] = true;
    if (!error) return true;
    return false;
  };
  ev = { fromBlock: "latest" };
  AccountsContract.events.AccountAdded(ev, async (error, event) => {
    if (!validateEvent(error, event, "AccountAdded")) return;
    const {
      transactionHash,
      returnValues: { _address, _name, _hpio, _url, _icon, _details },
    } = event;
    _userLedger[_address] = {
      transactionHash,
      address: _address,
      name: _name,
      hpio: _hpio,
      url: _url,
      icon: _icon,
      details: _details,
    };
    setGlobalState("userLedger", { ..._userLedger });
    setGlobalState("notification", {
      text: `${event.returnValues._name} just joined MediSend network`,
      type: "success",
    });
  });
  AccountsContract.events.AccountRemoved(ev, async (error, event) => {
    if (!validateEvent(error, event, "AccountRemoved")) return;
    delete _userLedger[event.returnValues._address];
    setGlobalState("userLedger", { ..._userLedger });
    setGlobalState("notification", {
      text: `User just left MediSend network`,
      type: "warning",
    });
  });
};

const processTransferEvents = async () => {
  // first process past events
  _transferLedger = {};

  const _processTransferRecordedEvent = async (
    { transactionHash, returnValues },
    isWaitToDecrypt = false
  ) => {
    const { _from, _to } = returnValues;
    if (![_from, _to].includes(ethAccount)) return; // only note txns that involve this client
    const nonCurrAcct = _to === ethAccount ? _from : _to; // list the txn for both to and from this client
    if (!_transferLedger[nonCurrAcct]) _transferLedger[nonCurrAcct] = [];
    // we add a waiting txn while the user is waiting for the peer to create a txn. if this is the case, remove waiting
    if (
      _transferLedger[nonCurrAcct].length > 0 &&
      _transferLedger[nonCurrAcct][0].transactionHash === "waiting"
    )
      _transferLedger[nonCurrAcct].shift();
    // we remove all waiting transactions with this filename
    _transferLedger[nonCurrAcct] = _transferLedger[nonCurrAcct].filter(
      ({ transactionHash }) => transactionHash !== "waiting"
    );
    const entry = {
      transactionHash,
      ...returnValues,
      _name: returnValues._name,
    };
    // for single events, we wait to decrypt
    if (isWaitToDecrypt)
      return _transferLedger[nonCurrAcct].unshift({
        ...entry,
        _name: await encrypt(returnValues._name, true),
      });
    _transferLedger[nonCurrAcct].push(entry);
    encryptThen(
      returnValues._name,
      true,
      nonCurrAcct,
      _transferLedger[nonCurrAcct].length - 1
    ).then(({ text, acct, index }) => {
      _transferLedger[acct][index]._name = text;
    });
  };

  const pastEventsFrom = await fetchEventsInChunks(
    AccountsContract,
    "TransferRecorded",
    0,
    "latest",
    {
      filter: { _from: ethAccount },
    }
  );

  const pastEventsTo = await fetchEventsInChunks(
    AccountsContract,
    "TransferRecorded",
    0,
    "latest",
    {
      filter: { _to: ethAccount },
    }
  );
  const pastEvents = [...pastEventsFrom, ...pastEventsTo].sort(
    (a, b) => a.returnValues._timestamp - b.returnValues._timestamp
  );
  for (let i = pastEvents.length - 1; i >= 0; i--)
    _processTransferRecordedEvent(pastEvents[i]);

  setGlobalState("transferLedger", _transferLedger);
  setGlobalState(
    "heading",
    isLoggedInAsAdmin ? "MediSend Admin" : _userLedger[ethAccount].name
  );
  // next, listen to new events
  const validateEvent = (error, event, alias) => {
    if (Boolean(eventTransactionHashTable[event.transactionHash])) return false;
    eventTransactionHashTable[event.transactionHash] = true;
    if (!error) return true;
    return false;
  };
  const _processLiveTransferEvent = async (error, event) => {
    if (!validateEvent(error, event, "TransferRecorded")) return;
    await _processTransferRecordedEvent(event, true);
    setGlobalState("transferLedger", { ..._transferLedger });
  };
  AccountsContract.events.TransferRecorded(
    { fromBlock: "latest", filter: { _from: ethAccount } },
    _processLiveTransferEvent
  );
  AccountsContract.events.TransferRecorded(
    { fromBlock: "latest", filter: { _to: ethAccount } },
    _processLiveTransferEvent
  );
};

/**
 * Validate whether the given account is registered with the Accounts SmartContract.
 * Defaults to the current metamask account
 */
const isAccountRegistered = async (_address = ethAccount) =>
  Boolean(await AccountsContract.methods.getNameForAddress(_address).call());

/**
 * Acknowledge a file transfer.
 * _onAccepted(txnHash) is called when the user presses the Accept button on Metamask (and hence the transaction gets submitted for mining)
 * _onMined(txnReceipt) is called when the user transaction has been mined
 */
const acknowledgeTransfer = async (
  _from,
  _hash,
  _filename,
  _size,
  _proposedlId,
  _acceptedId,
  _onAccepted,
  _onMined
) => {
  try {
    // Validate inputs
    if (!_from || !_hash || !_filename || _size === undefined) {
      throw new Error("Missing required parameters for acknowledgeTransfer");
    }

    return await doTransact(
      AccountsContract.methods.acknowledgeTransfer,
      [_from, _hash, _filename, _size, _proposedlId, _acceptedId],
      null,
      "Acknowledging File Transfer",
      _onMined,
      _onAccepted,
      (error) => {
        console.error("Acknowledgment failed:", error);
        _onMined && _onMined(error);
      }
    );
  } catch (error) {
    console.error("Error in acknowledgeTransfer:", error);
    _onMined && _onMined(error.message);
    throw error;
  }
};

/**
 * Add an user
 */
const addUser = async (
  address,
  name,
  hpio,
  url = "",
  icon = "",
  details = ""
) =>
  new Promise(function (resolve, reject) {
    doTransact(
      AccountsContract.methods.addAccount,
      [address, name, hpio, url, icon, details],
      null,
      "Adding User",
      (receipt) => {
        if (!receipt.status) {
          setGlobalState("notification", {
            text: `Error while Adding User`,
            type: "error",
          });
          return reject(false);
        }
        return resolve(`User added successfully`);
      }
    );
  });

/**
 * Remove a user
 */
const removeUser = async (address) =>
  new Promise(function (resolve, reject) {
    doTransact(
      AccountsContract.methods.removeAccount,
      [address],
      null,
      "Removing User",
      (receipt) => {
        if (!receipt.status) {
          setGlobalState("notification", {
            text: `Error while Removing User`,
            type: "error",
          });
          return reject(false);
        }
        return resolve(`User removed successfully`);
      }
    );
  });

/**
 * A generic method that executes a txnFunction(). Its does neccessary updates to the UI (locking, notification, etc)
 * @param {*} txnFunction
 * @param {*} args
 * @param {*} caption
 * @param {*} mined     a callback when the txn has been mined. on success, the receipt gets passed
 * @param {*} acepted   a callback when the txn has been accepted by the user and is being mined
 * @param {*} rejected  a callback when the txn has been rejected by the user
 */

// Improved Transaction Handling with Error Handling
const doTransact = async (
  txnFunction,
  args,
  value,
  caption,
  mined,
  accepted,
  rejected
) => {
  try {
    // Ensure web3 and account are available
    if (!window.ethereum || !window.web3) {
      throw new Error("MetaMask not detected");
    }

    const accounts = await window.ethereum.request({
      method: "eth_requestAccounts",
    });

    if (!accounts || accounts.length === 0) {
      throw new Error("No MetaMask account available");
    }

    const ethAccount = accounts[0];

    // Get current gas price
    const gasPrice = await web3.eth.getGasPrice();

    // Estimate gas for the transaction
    const gasEstimate = await txnFunction.apply(null, args).estimateGas({
      from: ethAccount,
      value: web3.utils.toWei(value || "0", "finney"),
    });

    // Add 20% buffer to gas estimate
    const gasLimit = Math.ceil(gasEstimate * 1.2);

    // Create transaction
    const transaction = txnFunction.apply(null, args).send({
      from: ethAccount,
      value: web3.utils.toWei(value || "0", "finney"),
      gasPrice: gasPrice,
      gas: gasLimit,
    });

    // Set up transaction monitoring
    const pollTxn = async (hash) => {
      try {
        const receipt = await web3.eth.getTransactionReceipt(hash);
        if (receipt) {
          mined && mined(receipt);
          return;
        }
        setTimeout(() => pollTxn(hash), 2000); // Increased polling interval
      } catch (error) {
        console.error("Error polling transaction:", error);
        rejected && rejected(error.message);
      }
    };

    // Handle transaction events
    transaction
      .on("transactionHash", (hash) => {
        console.log("Transaction hash:", hash);
        accepted && accepted(hash);
        setGlobalState("notification", {
          text: `Transaction submitted for ${caption}`,
          type: "info",
        });
        pollTxn(hash);
      })
      .on("error", (error) => {
        console.error("Transaction error:", error);
        rejected && rejected(error.message);
        mined && mined(error.message);
      });

    return transaction;
  } catch (error) {
    console.error("Transaction setup error:", error);
    rejected && rejected(error.message);
    mined && mined(error.message);
    throw error;
  }
};

export {
  initBlockchain,
  getBalance,
  isAccountRegistered,
  acknowledgeTransfer,
  addUser,
  removeUser,
};

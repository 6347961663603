import React, { useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Paper } from "@material-ui/core";
import { useGlobalState, setGlobalState } from "../../store";
import {
  downloadFile,
  fBytes,
  encrypt,
  openEtherscanAccount,
  getBackground,
  addWaitingTransaction,
} from "../../util";
import { acknowledgeTransfer } from "../../blockchain";
import { rejectAfterPreview, sendAcknowledgement } from "../../socket";
import Preview from "./Preview";
const useStyles = makeStyles((theme) =>
  createStyles({
    summary: {
      marginTop: "6rem",
      display: "flex",
      justifyContent: "space-evenly",
      flexWrap: "wrap",
      width: "100%",
    },
    buttons: {
      margin: "1rem 0 4rem 0",
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
    },
    info: {
      width: "40%",
    },
    link: {
      textDecoration: "underline",
      "&:hover": {
        color: "#cc0000",
        cursor: "pointer",
      },
    },
  })
);

export default function FileDownloader() {
  const classes = useStyles();
  const [fileDownloadObj, setFileDownloadObj] =
    useGlobalState("fileDownloadObj");
  const [, setEthereumTxnBeingMined] = useGlobalState("ethereumTxnBeingMined");
  const [ethereumAccount] = useGlobalState("ethereumAccount");
  const [, setSelectedClient] = useGlobalState("selectedClient");
  const [, setSystemState] = useGlobalState("systemState");
  const [userLedger] = useGlobalState("userLedger");
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const acknowledgeAndDownload = async () => {
    if (isAcknowledged)
      return downloadFile(fileDownloadObj.url, fileDownloadObj.filename);
    acknowledgeTransfer(
      fileDownloadObj.from.address,
      fileDownloadObj.hash,
      await encrypt(fileDownloadObj.filename),
      fileDownloadObj.size,
      fileDownloadObj.proposedId,
      fileDownloadObj.acceptedId,
      (txnHash) => {
        setEthereumTxnBeingMined(txnHash);
        downloadFile(fileDownloadObj.url, fileDownloadObj.filename);
        // create a waiting... entry until the txn is mined
        addWaitingTransaction(
          fileDownloadObj.from.address,
          ethereumAccount,
          fileDownloadObj.filename,
          fileDownloadObj.size
        );
        setSelectedClient(fileDownloadObj.from.address);
        sendAcknowledgement(fileDownloadObj.filename);
        setIsAcknowledged(true);
      },
      () => setEthereumTxnBeingMined(null)
    );
  };

  const Sender = () => (
    <Paper elevation={2} className={classes.info}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`Sender`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.from.name}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`Ethereum Address`}</Typography>
            </TableCell>
            <TableCell
              className={classes.link}
              onClick={() => openEtherscanAccount(fileDownloadObj.from.address)}
            >
              <Typography className={classes.link}>
                {fileDownloadObj.from.address}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`HPIO`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.from.hpio}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`website`}</Typography>
            </TableCell>
            <TableCell
              className={classes.link}
              onClick={() => window.open(fileDownloadObj.from.url, "_blank")}
            >
              <Typography className={classes.link}>
                {fileDownloadObj.from.url}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`Details`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.from.details}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  const File = () => (
    <Paper elevation={2} className={classes.info}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`Patient Name`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.patientName}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`Patient DOB`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.patientDOB}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`File type`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>
                {fileDownloadObj?.fileType.split("/")[1] || "pdf"}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`File size`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{`${fileDownloadObj.size} B (${fBytes(
                fileDownloadObj.size
              )})`}</Typography>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography color="secondary">{`File hash`}</Typography>
            </TableCell>
            <TableCell>
              <Typography>{fileDownloadObj.hash}</Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );

  if (!fileDownloadObj) return <div />;
  const { icon } = userLedger[fileDownloadObj.fromAccountId];
  return (
    <div>
      <AppBar
        position="fixed"
        style={{ background: getBackground(ethereumAccount), top: 0 }}
      >
        <Toolbar>
          {icon && (
            <img
              src={icon}
              alt="x"
              style={{ width: "2rem", height: "2rem", marginRight: "1rem" }}
            />
          )}
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            {`Received File from ${fileDownloadObj.from.name}`}
          </Typography>
        </Toolbar>
      </AppBar>
      <div className={classes.summary}>
        <Sender />
        <File />
        {/* perview code */}
        <div style={{ margin: "3rem 0 2rem 0" }}>
          <Preview file={fileDownloadObj.url} type={fileDownloadObj.fileType} />
        </div>
      </div>
      <div className={classes.buttons}>
        <Button
          variant="contained"
          color={isAcknowledged ? "secondary" : "primary"}
          style={{ width: "10rem" }}
          onClick={() => {
            setFileDownloadObj(null);
            setGlobalState("acceptFile", false);
            setSystemState("idle");
            !isAcknowledged &&
              rejectAfterPreview(
                fileDownloadObj.from.address,
                fileDownloadObj.filename,
                "INCORRECT_FILE"
              );
            setIsAcknowledged(false);
          }}
        >
          {isAcknowledged ? "Close" : "Decline"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ width: "10rem" }}
          onClick={acknowledgeAndDownload}
        >
          Download
        </Button>
      </div>
    </div>
  );
}
